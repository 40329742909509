.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}

#viewer-container {
  background: #ffffff;
  align-self: center;
  width: 80vw;
  min-height: 65vh;
  border-radius: 10px;
  padding: 30px;
  overflow: hidden;
}

#viewer-container * {
  all: revert;
  text-align: left;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
