body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #00bfa5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  color: #ffffff;
  font-size: 5rem;
}

textarea {
  max-height: 600px;
  height: 65vh;
  border: none;
  border-radius: 5px;
  outline: none;
  resize: none;
  background: #444;

  padding: 5px;
  margin-bottom: 10px;
  color: #f781d3;
  font-size: 13pt;
  box-shadow: inset 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
}

form {
  align-self: center;
  max-width: 900px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}

form button {
  background: #007464;
  border: none;
  border-radius: 5px;
  padding: 15px 5px;

  align-self: center;
  max-width: 500px;
  width: 100%;

  cursor: pointer;
  font-size: 16pt;
  font-weight: bold;
  color: #ffffff;
  transition: all 0.2s;
}

form button:hover {
  opacity: 0.8;
  box-shadow: inset 0px 0px 0px 4px rgba(0, 0, 0, 0.2);
}

@media (max-width: 767px) {
  h1 {
    font-size: 2rem;
  }
}
